<template>
    <div class="" :class="$i18n.locale == 'en'?'en-style':'' ">
        <div class="page_chongzhi">
            <div class="page_cz_top">
                <div class="page_cz_jifen">{{$t('main.rempoint')}}</div>
                <div class="page_cz_jifen_1">{{score}}</div>
            </div>
            <div class="page_cz_bg">
                <div class="flex_jcc">
                    <div class="cw_title">{{$t('main.pointsrecharge')}}</div>
                    <div class="zgj_title" @click="goList">{{$t('main.bill')}}</div>
                </div>
                <div class="item_list">
                    <div :class="['item',actIndex == index?'act_item':'' ]" v-for="(item , index) in list" :key="index" @click="changeAct(index)">
                        <div :class="['item_money', `money_bg_${index+1>=4?4:index+1}`]"></div>
                        <div>
                            <div class="flex">
                                <div class="item_jf">{{item.score}} {{$t('main.points')}}</div>
                                <div class="item_price">￥{{parseFloat(item.pay_amount)}}</div>
                            </div>
                            <div class="item_count">{{item.content}}</div>
                            <div class="item_count">{{$t('main.sendwAiAudio',{N:item.audio_count})}} </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pop pop-cy-rz" v-show="qrcode1" >
            <div class="pop-qrcode"> 
                <div  @click.stop>
                <img :src="nowZS" alt="" />
                <div>{{$t('main.puwxpay')}}</div>
                <div>{{$t('main.vtoqrc')}}</div>
                </div>
                <img class="pop-close" @click="closeQr1" src="../assets/img/close_white.png" alt="">
            </div>
          </div>

            <div class="page_cz_bottom">
                <div>
                    <div class="page_cz_bottom_price">
                        <span style="font-size: 0.12rem">￥</span>{{amount}}
                    </div>
                    <div class="page_cz_bottom_text">{{$t('main.pchartips')}}</div>
                </div>
                <div class="cz_button" @click="chooseCz">
                    {{$t('main.confirmRech')}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
    data(){
        return{
            actIndex:-1,
            price:0,
            list:[],
            amount:0,
            score:0,
            ischongzhi:false,
            is_pc:false,
            qrcode1:false,
            nowZS:'',
            is_img:false,
            audio_count:0
        }
    },
    mounted(){
        this.axios.post('/order/getRechargeConfig').then((res)=>{
            if(this.$i18n.locale == 'en'){
                let data = res.data.data.config
                for(let i = 0; i < data.length; i++){
                    if(data[i].content_intl){
                        res.data.data.config[i].content = data[i].content_intl
                    }
                }
            }
            this.list = res.data.data.config
            this.score = res.data.data.user.artist.score
        })
        this.is_pc = this.IsPC()
    },
    methods:{
        IsPC(){  
        let userAgentInfo = navigator.userAgent;
        let Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
        let flag = true;  
        for (let v = 0; v < Agents.length; v++) {  
            if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
        }  
        return flag;
        },

        goList(){
            this.$router.push('/ScoreRecord')
        }, 
        changeAct(index){
            this.actIndex = index
            this.amount = this.list[index].pay_amount
            this.audio_count = this.list[index].audio_count
        },
        chooseCz(){
            if(this.ischongzhi){
                return
            }
            if(this.is_pc){
                if(this.is_img){
                        this.closeQr1()
                }else{
                        this.DownLoadQRcode()
                }
                return
            }
            weui.actionSheet([
                {
                label: this.$t('main.wxpay'),
                onClick: () => {
                    this.chongzhi();
                },
                },
                {
                label: this.$t('main.qrpay'),
                onClick: () => {
                    if(this.is_img){
                        this.closeQr1()
                    }else{
                        this.DownLoadQRcode()
                    }
                    
                },
                },
            ]);
        },
        chongzhi(){
            if(this.is_pc){
                weui.alert(this.$t('main.pruwechat'),{buttons:[{label:this.$t('main.confIrm')}]})
                return
            }
            if(this.ischongzhi){
                return
            }
            if(this.actIndex == -1){
                weui.alert(this.$t('main.psrp'),{buttons:[{label:this.$t('main.confIrm')}]})
                return
            }
            this.ischongzhi = true
            let params = {
                amount:this.amount
            }
            this.axios.post('/order/recharge',params).then((res)=>{
                        
                        if(res.data.code == 0){
                            let wxpay = res.data.data
                            
                            WeixinJSBridge.invoke(
                            'getBrandWCPayRequest', {
                                appId:wxpay.appId,
                                timeStamp: wxpay.timestamp, 
                                nonceStr: wxpay.nonceStr, // 支付签名随机串，不长于 32 位
                                package: wxpay.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                                signType: wxpay.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                                paySign: wxpay.paySign,
                                
                            },(res)=>{
            if(res.err_msg == "get_brand_wcpay_request:ok" ){
                let msg = this.audio_count > 0 ?   `{{$t('main.epcosr')}}${this.audio_count}个作品AI语音`:this.$t('main.paysucc')
                weui.alert(msg,{buttons:[{label:this.$t('main.confIrm'),onClick:()=>{
                    this.axios.post('/order/getRechargeConfig').then((res)=>{
                        
                        this.list = res.data.data.config
                        this.score = res.data.data.user.artist.score
                        if(this.$route.query.status){
                            this.$router.go(-1)
                        }
                    })
                    this.ischongzhi = false
                }}]})
            }else{
                this.ischongzhi = false
                weui.alert(this.$t('main.payfail'),{buttons:[{label:this.$t('main.confIrm')}]})
            } })
            }else{
                weui.alert(res.data.msg,{buttons:[{label:this.$t('main.confIrm')}]})
                this.ischongzhi = false
            }
            })
        },
        DownLoadQRcode(item) {
            
            if(this.actIndex == -1){
                weui.alert(this.$t('main.psrp'),{buttons:[{label:this.$t('main.confIrm')}]})
                return
            }
            this.loading = weui.loading(this.$t('main.loading'))
            this.ischongzhi = true
            let params = {
                amount:this.amount,
                type:2
            }
            this.axios.post('/order/recharge',params).then((res)=>{
                // console.log(res)
                if(res.data.code == 0){
                    let url = res.data.data.code_url;
                    let div = document.createElement("div");
                    let code = new QRCode(div, {
                        text: url, //要生成二维码的网址
                        width: 256, //图像宽度
                        height: 256, //图像高度
                        colorDark: "#000000", //前景色
                        colorLight: "#ffffff", //背景色
                        margin: 0, //外边距
                        correctLevel: QRCode.CorrectLevel.L, //容错级别。属性值有：QRCode.CorrectLevel.L、QRCode.CorrectLevel.M、QRCode.CorrectLevel.Q、QRCode.CorrectLevel.H
                    });
                    let canvas = code._el.querySelector("canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
                    this.nowZS = canvas.toDataURL("image/png");
                    this.qrcode1 = true;
                    this.ischongzhi = false
                    this.loading.hide()
                    this.order = setInterval(()=>{
                        this.axios.post('order/checkOrderStatus',{number:res.data.data.number}).then((res)=>{
                            // console.log(res)
                            if(res.data.code == 0){
                               let msg = this.audio_count > 0 ?   this.$t('main.psygv',{N:this.audio_count}):this.$t('main.paysucc')
                                weui.alert(msg,{buttons:[{label:this.$t('main.confIrm'),onClick:()=>{
                                    this.axios.post('/order/getRechargeConfig').then((res)=>{
                        
                                        this.list = res.data.data.config
                                        this.score = res.data.data.user.artist.score
                                        this.is_img = false
                                        this.qrcode1 = false
                                        if(this.$route.query.status){
                                            this.$router.go(-1)
                                        }
                                    })
                                    
                                }}]})
                                clearInterval(this.order)
                            }
                        })
                    },5000)
                }
            })
            
            
        },
        closeQr1(){
            this.qrcode1 = !this.qrcode1
        }

    }
}
</script>

<style scoped>
.page_chongzhi{
    width: 100%;
    min-height: 100vh;
    background: url(../assets/img/index_bg_fn.png) no-repeat;
    background-size: cover;
        background-attachment: fixed;
    position: relative;
}
.page_cz_top{
    width: 100%;
    height: 1.4rem;
    background: url(../assets/img/jifen_bg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.page_cz_jifen{
    font-size: 0.18rem;
    color: #f0de8a;
}
.page_cz_jifen_1{
    font-size: 0.3rem;
    color: #f0de8a;
    margin-top: 0.1rem;
}
.flex_jcc{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.page_cz_bg{
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.15rem;
    padding-top: 0.15rem;
}
.cw_title{
    margin-left: 0.25rem;
	font-size: 0.15rem;
    position: relative;
    color: #a6210e;
    font-weight: 600;
}
.cw_title::before{
    content: '';
    display: block; position: absolute;
    left: -0.25rem;
    top: 0.07rem;
    background: url(../assets/img/xwt.png) no-repeat;
    background-size: cover;
    width: 0.145rem;
    height: 0.1rem;
}
.cw_title::after{
    content: '';
    display: block; position: absolute;
    right: -0.25rem;
    top: 0.07rem;
    background: url(../assets/img/xwt.png) no-repeat;
    background-size: cover;
    width: 0.145rem;
    height: 0.1rem;
}
.zgj_title{
    margin-right: 0.12rem;
	font-size: 0.12rem;
    position: relative;
    color: #a6210e;
}
.zgj_title::before{
    content: '';
    display: block; position: absolute;
    left: -0.12rem;
    top: 0.02rem;
    background: url(../assets/img/zgj.png) no-repeat;
    background-size: cover;
    width: 0.065rem;
    height: 0.12rem;
}
.zgj_title::after{
    content: '';
    display: block; position: absolute;
    right: -0.12rem;
    top: 0.02rem;
    background: url(../assets/img/zgj.png) no-repeat;
    background-size: cover;
    transform: rotate(180deg);
    width: 0.065rem;
    height: 0.12rem;
}
.item_list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0.25rem;
}
.item{
    width: 1.68rem;
    height: 0.8rem;
    border-radius: 0.05rem;
    background-color: #fff;
    margin-bottom: 0.15rem;
    display: flex;
    align-items: center;
}
.en-style .item{
    width: 100%;
}
.act_item{
    background: url(../assets/img/border_jf_r.png) no-repeat;
    background-size: 100% 100%;
    background-color: #fff;
}
.item_money{
    margin-left: 0.115rem;
    width: 0.4rem;
    height: 0.6rem;
}
.money_bg_1{
    background: url(../assets/img/money_1.png) no-repeat;
    background-size: cover;
}
.money_bg_2{
    background: url(../assets/img/money_2.png) no-repeat;
    background-size: cover;
}
.money_bg_3{
    background: url(../assets/img/money_3.png) no-repeat;
    background-size: cover;
}
.money_bg_4{
    background: url(../assets/img/money_4.png) no-repeat;
    background-size: cover;
}
.flex{
    display: flex;
    align-items: center;
}
.item_jf{
    font-size: 0.125rem;
    white-space: nowrap;
    color: #000;
}
.item_price{
    margin-left: 0.09rem;
    font-size: 0.105rem;
    color: #4b4b4b;
}
.item_count{
    font-size: 0.125rem;
    color: #4b4b4b;
}
.page_cz_bottom{
    padding: 0 0.15rem;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    height: 0.65rem;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.en-style .page_cz_bottom{
    height: auto;
}
.en-style .page_cz_bottom>div:first-child{
    width: 65%;
}
.cz_button{
    background: #a6210e;
    padding: 0 0.22rem;
    height: 0.36rem;
    color: #fff;
    font-size: 0.15rem;
    line-height: 0.36rem;
    border-radius: 0.05rem;
}
.page_cz_bottom_text{
    color: #868686;
    font-size: 0.12rem;
}
.page_cz_bottom_price{
    font-size: 0.18rem;
    color: #4b4b4b;
}
.pop-qrcode {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  background-size: 100% 100%;
  
 display: flex;
 flex-direction: column;
 align-items: center;

}
.pop-qrcode > div:nth-child(1){
    background: #21aa38;
    width: max-content;
  height: max-content;
  padding: 0.15rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pop-qrcode > div > img {
  width: 2.27rem;
  height: 2.27rem;
  padding: 0.1rem;
  box-sizing: border-box;
  background-color: #fff;
  
  object-fit: contain;
  margin-bottom: 0.05rem;
}
.pop-qrcode div {
    color: #fff;
}
.pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  /* display: none; */
}
.pop-close{
    width: 0.3rem;
    height: 0.3rem;
    margin-top: 15px;
}
</style>